import { type SelectOption } from '@core/components/select/select.component';

export interface CustomField {
  id: number;
  title: string;
  key?: string;
  type: CustomFieldType;
  sectionTitle: string;
  isReadonly?: boolean;
  sectionAccess: CustomFieldAccessType;
  status: CustomFieldStatus;
  canBeDeleted: boolean;
  helpText?: string;
  options?: SelectOption[];
}

export enum CustomFieldStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum CustomFieldAccessType {
  Internal = 'Internal',
  External = 'External',
}

export enum CustomFieldType {
  Text = 'String',
  Contact = 'Contact',
  OptionList = 'Enumeration',
  MultiOptionList = 'MultiEnumeration',
  Location = 'Location',
  Number = 'Number',
  Date = 'Date',
  /*     Image = 'Image' */
}
